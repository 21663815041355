import React, { useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import MetaTags from '../components/Seo/MetaTags';

const ApiPage = () => {
  const { i18n } = useTranslation('API');
  const { hash } = useLocation();
  const iframeRef = useRef(null);

  const { language } = i18n;
  const title =
    language === 'ru' ? 'Открытое API SpySERP.com' : 'Open API SpySERP.com';
  const apiFileName = language === 'ru' ? 'indexRu.html' : 'indexEn.html';

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.location.hash = hash;
    }
  }, [hash]);

  return (
    <>
      <MetaTags title={title} />
      <iframe
        className="api-frame"
        ref={iframeRef}
        src={`/api-docs/${apiFileName}`}
        title={title}
      />
    </>
  );
};

export default ApiPage;
